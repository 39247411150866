exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-impressumdatenschutz-js": () => import("./../../../src/pages/impressumdatenschutz.js" /* webpackChunkName: "component---src-pages-impressumdatenschutz-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-innovation-js": () => import("./../../../src/pages/innovation.js" /* webpackChunkName: "component---src-pages-innovation-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-newslettersuccess-js": () => import("./../../../src/pages/newslettersuccess.js" /* webpackChunkName: "component---src-pages-newslettersuccess-js" */),
  "component---src-pages-produkt-js": () => import("./../../../src/pages/produkt.js" /* webpackChunkName: "component---src-pages-produkt-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-event-post-js": () => import("./../../../src/templates/event-post.js" /* webpackChunkName: "component---src-templates-event-post-js" */),
  "component---src-templates-pressrelease-post-js": () => import("./../../../src/templates/pressrelease-post.js" /* webpackChunkName: "component---src-templates-pressrelease-post-js" */)
}

